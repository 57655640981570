<template>
  <div class="message app-container">
    <header class="message-title">
      <h2>系统消息列表</h2>
    </header>
    <eHeader :sup_this="sup_this" :query="query"></eHeader>
    <div class="operation">
      <addBtn v-p="['externaladmin:messageTemplate:create']"></addBtn>
    </div>
    <CommonTable height="auto" :selection="false" :cols="cols" :infoData="data" :tableLoading="tableLoading">
      <template #specificTypeSlot="{ row }">
        {{ row.messageType === 1 ? row.businessTypeName : row.templateTypeName }}
      </template>
      <template #isEnabledSlot="{ row }">
        <el-switch
          v-model="row.isEnabled"
          @change="switchChange(row.id, $event)"
          active-color="#3841DB"
          inactive-color="#909399"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </template>
      <template #operationSlot="{ row }">
        <editBtn class="line" :row="row" v-p="['externaladmin:messageTemplate:update']"></editBtn>
        <delBtn :row="row" :init="init" v-p="['externaladmin:messageTemplate:delete']"></delBtn>
      </template>
    </CommonTable>
    <PaginationBar
      :page="page"
      :size="size"
      :fSize="[10, 20, 30, 50]"
      :total="total"
      @refreshTableEventFun="refreshTableEventFun"
    />
  </div>
</template>

<script>
import eHeader from './module/header.vue'
import addBtn from './module/addBtn.vue'
import editBtn from './module/editBtn.vue'
import delBtn from './module/delBtn.vue'
import initDataMixin from '@/mixins/initData'
import { cols } from './tableCols'
import { updateTemplate } from '@/api/messageApi'
export default {
  mixins: [initDataMixin],
  components: {
    eHeader,
    addBtn,
    editBtn,
    delBtn
  },
  props: {},
  data() {
    return {
      cols,
      sup_this: this,
      url: '/externaladmin/messageService/messageTemplate/list'
    }
  },
  computed: {},
  methods: {
    async switchChange(id, val) {
      const { code } = await updateTemplate({ id, isEnabled: val })
      if (code === 0) {
        this.$message.success('操作成功')
      } else {
        this.$message.error('操作失败')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.message {
  padding: 30px;
  .message-title {
    h2 {
      font-size: 20px;
      font-weight: 400;
    }
  }
  .operation {
    margin: 10px 0 30px 0;
  }
  .line::after {
    content: '|';
    line-height: 1;
    font-size: 12px;
    margin: 0 10px;
    display: inline-block;
    transform: scaleX(0.5);
    color: #dcdee0;
  }
}
</style>
